exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-course-tsx": () => import("./../../../src/pages/course.tsx" /* webpackChunkName: "component---src-pages-course-tsx" */),
  "component---src-pages-courses-tsx": () => import("./../../../src/pages/courses.tsx" /* webpackChunkName: "component---src-pages-courses-tsx" */),
  "component---src-pages-error-tsx": () => import("./../../../src/pages/error.tsx" /* webpackChunkName: "component---src-pages-error-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-mentor-guidelines-tsx": () => import("./../../../src/pages/info/mentor-guidelines.tsx" /* webpackChunkName: "component---src-pages-info-mentor-guidelines-tsx" */),
  "component---src-pages-info-privacy-policy-tsx": () => import("./../../../src/pages/info/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-info-privacy-policy-tsx" */),
  "component---src-pages-info-refund-policy-tsx": () => import("./../../../src/pages/info/refund-policy.tsx" /* webpackChunkName: "component---src-pages-info-refund-policy-tsx" */),
  "component---src-pages-info-terms-of-use-tsx": () => import("./../../../src/pages/info/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-info-terms-of-use-tsx" */),
  "component---src-pages-info-tsx": () => import("./../../../src/pages/info.tsx" /* webpackChunkName: "component---src-pages-info-tsx" */),
  "component---src-pages-mentor-apply-tsx": () => import("./../../../src/pages/mentor/apply.tsx" /* webpackChunkName: "component---src-pages-mentor-apply-tsx" */),
  "component---src-pages-mentor-tsx": () => import("./../../../src/pages/mentor.tsx" /* webpackChunkName: "component---src-pages-mentor-tsx" */),
  "component---src-pages-referral-tsx": () => import("./../../../src/pages/referral.tsx" /* webpackChunkName: "component---src-pages-referral-tsx" */),
  "component---src-pages-web-tsx": () => import("./../../../src/pages/web.tsx" /* webpackChunkName: "component---src-pages-web-tsx" */)
}

